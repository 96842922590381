// $('.dt-responsive').DataTable({
    // columnDefs: [{
        // orderable: false,
        // targets: 0
        // }],
    // //"ordering": false, // false to disable sorting (or any other option)
    // "scrollX": true,
    // "scrollY": "130px",
    // dom: "Bfrtip",
    // buttons: ["excel","pdf","print"]

// });
// $('.dt-responsive-only').datatable({
    // "drawcallback": function( settings ) {    
        // $('[data-toggle="tooltip"]').tooltip();
        // $('[rel="tooltip"]').tooltip();                      
    // },
    // "scrolly": "130px",
    // "scrollcollapse": true,
    // "searching": false,
    // "paging": false,
    // "binfo" : false
// });

$("#btnShow").click(function(){  
    $(".alert").hide().show('medium');
  });

$('.datepicker').datepicker();
$('[data-toggle="tooltip"]').tooltip();
$('[rel="tooltip"]').tooltip();

$('.date.datepicker').parents('td').css("max-width", "unset");
$('select.custom-select-sm').parents('td').css("max-width", "unset");
$('.mw-200').parents('td').css("max-width", "unset");
$('.multi-select').parents('td').css("overflow", "visible");
$('td.mw-unset').css("max-width", "unset");

$('[data-toggle="popover"]').popover({
    trigger: "focus",
    html: true,
    content: '<ul class="list-group list-group-flush"><li class="list-group-item">Johny1.Cash@XYX.com</li><li class="list-group-item">Johny2.Cash@XYX.com</li><li class="list-group-item">Johny3.Cash@XYX.com</li></ul>'
});

$(function () { 
    $('[data-toggle="popover"]').popover({
        trigger: 'focus',
        placement: 'top'
    })
})

$('.multi-select').multiselect({
    buttonWidth : '150px',
    includeSelectAllOption : true,
    nonSelectedText: 'Select Supplier/s' //,
    // buttonMaxWidth : '200px'
});                    

function getSelectedValues() {
    var selectedVal = $("#multiselect").val();
    for(var i=0; i<selectedVal.length; i++){
        function innerFunc(i) {
            setTimeout(function() {
                location.href = selectedVal[i];
            }, i*2000);
        }
        innerFunc(i);
    }
}

// Add Supplier
$('#basic-addon2').click(function(){
    $('.add-email').css("display","block");
});
$('#basic-addon3').click(function(){
    $('.add-email').css("display","none");
});

// Add Supplier ENDS here

// commercial Docs
$("#addPORecord").click(function(){
    $('.toast').toast('show');
});
$('.show-details').click(function(){
    $(this).focusout();
    $('.card-document-list').show(1000);
    $(".focus-me").focusin({preventScroll:false});
});
$('#close-document-list').click(function(){
    $('.card-document-list').hide(500);
});
// commercial Docs ENDS here

// external User
$('.add-portOfLoading').click(function(){
    $('#port-of-loading').css("display","block");
});
// ENDS here

// Settings page
$('.show-users').click(function(){
    $(this).focusout();
    $('.user-list-bx').show(1000);
    $(".focus-me").focusin({preventScroll:false});
});
$('#close-user-list').click(function(){
    $('.user-list-bx').hide(500);
});
// JS for settings ENDS here

// supplier Instructions
$(".add-doc-btn").click(function(){
    $("#myTextField").focus();
});
focusMethod = function getFocus() {           
    $("#myTextField").focus();
}

$('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
    $($.fn.dataTable.tables(true)).DataTable()
       .columns.adjust();
});